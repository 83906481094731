import logo from "./logo.svg";
import "./App.css";

import hero_img from "./assets/img/image-removebg-preview.png";
import pora_company_logo from "./assets/img/pora_innovations_company_logo.png";
import truechat_logo from "./assets/img/two-way-chat-bubble-icon-removebg.png";
import truechat_logo_new from "./assets/img/new_logo_1.png";
import hero_img_gif from "./assets/img/hero-img-unscreen.gif";

import {
  chatbot_images,
  industries_images,
  platforms,
  features_images,
} from "./constant";
import { Route, Routes, useNavigate } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfCondition from "./TermsOfCondition";


function App() {
  const navigate = useNavigate();
  const chatbotUses = [
    {
      img: chatbot_images.chatbot_payments,
      heading: "Payments",
      info: "Facilitate quick and secure payments through conversational interfaces",
    },
    {
      img: chatbot_images.chatbot_services,
      heading: "Services",
      info: "Streamline service delivery by providing instant support and personalized assistance.",
    },
    {
      img: chatbot_images.chatbot_grievances,
      heading: "Grievances",
      info: "Efficiently handle grievances by offering immediate response and tracking resolution progress.",
    },

    {
      img: chatbot_images.chatbot_marketing,
      heading: "Marketing",
      info: "Enhance marketing by engaging customers, personalizing interactions, and driving conversions.",
    },
    {
      img: chatbot_images.chatbot_query_resolution,
      heading: `Query Resolution`,
      info: "Swiftly resolve queries by providing instant, accurate information and support.",
    },
    {
      img: chatbot_images.chatbot_updates_notifications,
      heading: "Updates & Notifications",
      info: "Deliver timely updates and notifications directly to users, ensuring they stay informed.",
    },
    {
      img: chatbot_images.chatbot_document_management,
      heading: "Document Management",
      info: "Simplify document management by assisting with organization, retrieval, and sharing of files.",
    },
  ];
  const industries = [
    {
      img: industries_images.govt_institution,
      heading: "Govt. Institutions",
      info: "Instant verification of certificates within 10 seconds.",
    },
    {
      img: industries_images.bank_financial_institution,
      heading: "Banks & Financial Institutions",
      info: "Instant verification of certificates within 10 seconds.",
    },
    {
      img: industries_images.educational_institute,
      heading: "Educational Institutions",
      info: "Instant verification of certificates within 10 seconds.",
    },
    {
      img: industries_images.police_department,
      heading: "Police Department",
      info: "Instant verification of certificates within 10 seconds.",
    },
    {
      img: industries_images.real_estate,
      heading: "Real Estate",
      info: "Instant verification of certificates within 10 seconds.",
    },
    {
      img: industries_images.cooperative,
      heading: "Co-operatives",
      info: "Instant verification of certificates within 10 seconds.",
    },
    {
      img: industries_images.entertainment,
      heading: "Entertainment",
      info: "Instant verification of certificates within 10 seconds.",
    },
    {
      img: industries_images.transport,
      heading: "Transport",
      info: "Instant verification of certificates within 10 seconds.",
    },
    {
      img: industries_images.hospitality,
      heading: "Hospitality",
      info: "Instant verification of certificates within 10 seconds.",
    },
    {
      img: industries_images.fitness,
      heading: "Health & Fitness",
      info: "Instant verification of certificates within 10 seconds.",
    },
  ];
  const brands = [
    logo,
    logo,
    logo,
    logo,
    logo,
    logo,
    logo,
    logo,
    logo,
    logo,
    logo,
    logo,
  ];

  const features = [
    {
      logo: features_images.broadcast,
      heading: "Broadcast Communication",
      info: "Push out your campaigns and engage with high response WhatsApp groups",
    },
    {
      logo: features_images.chatbots,
      heading: "Chatbots",
      info: "Create no-code chatbots to provide instant response to common requests",
    },
    {
      logo: features_images.multiple_agents,
      heading: "Multiple Agents",
      info: "Share the Wati inbox across your team with support for an unlimited number of agents",
    },
    {
      logo: features_images.low_code_automation,
      heading: "Low-Code Automations",
      info: "Simplify mass communication and personalized response with automated tools",
    },
    {
      logo: features_images.ono_to_one_communication,
      heading: "1-2-1 communication",
      info: "Tag contacts, categorize and target them in groups using personalized communications",
    },
    {
      logo: features_images.easy_integration,
      heading: "Easy Integrations",
      info: "Pre-built integrations let you integrate with your tech stack and configure in a few clicks",
    },
  ];
  const chatbot_platforms = [
    platforms.whatsapp,
    platforms.facebook,
    platforms.gmail,
    platforms.linkedin,
    platforms.instagram,
    platforms.website,
  ];

  return (
    <div className="App">


      {/* hero section */}
      <div className="hero">
        <div className="hero_left">
          <div className="hero_left_main_text">
            Elevate Your Conversations with Intelligent Chatbots by
            TrueChat.live
          </div>
          <div className="hero_left_support_text">
            TrueChat.Live is a cutting-edge chatbot development platform
            designed specifically for social media, with a primary focus on
            WhatsApp
          </div>
          <a
            className="hero_clickToChat_button"
            target="_blank"
            href="https://wa.me/9137359450"
          >
            Click to Connect Now
          </a>
        </div>
        <div className="hero_right">
          <img
            // src="https://files.sunoresearch.com.br/p/uploads/2018/09/Elon-Musk-2-400x300.jpg"
            // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRA0c2Cb-X9aeP3P-06oUVKVeaSwapBHp2aDg&s"
            src={hero_img_gif}
            alt=""
            className="hero_right_img"
          />
        </div>
      </div>

      {/* chatbot uses */}
      <div className="chatbotUses">
        <div className="chatbotUses_section_heading">Chatbot Use Cases</div>
        <div className="chatbotCard_parent">
          {chatbotUses?.map((c) => {
            return (
              <div className="chatbotCard">
                <img className="chatbotCard_img" src={c?.img} />
                <div className="chatbotCard_heading">{c?.heading}</div>
                <div className="headingRedUnderline"></div>
                <div className="chatbotCard_info">{c?.info}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="brands">
        <div className="brands_heading">Features</div>
        <div className="brands_sub_heading">
          Smart tools for efficient and personalized communication
        </div>
        <div className="brands_items_parent">
          {features?.map((b) => {
            return (
              <div className="brands_item">
                <img className="brands_items_img" src={b?.logo} alt="" />
                <div className="brands_item_heading">{b?.heading}</div>
                <div className="brands_item_info">{b?.info}</div>
              </div>
            );
          })}
        </div>
      </div>

      {/* target industries */}
      <div className="industries">
        <div className="industries_section_heading">Industries We Serve</div>
        <div className="industriesCard_parent">
          {industries?.map((c) => {
            return (
              <div className="industriesCard">
                <img className="industriesCard_img" src={c?.img} />
                <div className="industriesCard_heading">{c?.heading}</div>
                <span className="headingRedUnderline"></span>
                {/* <div className="industriesCard_info">{c?.info}</div> */}
              </div>
            );
          })}
        </div>
      </div>

      <div className="chatbot_platforms">
        <div className="chatbot_platforms_heading">
          Instantly Engage with Prospects & Customers beyond Website
        </div>
        <div className="chatbot_platforms_items_parent">
          {chatbot_platforms?.map((b) => {
            return (
              <div className="chatbot_platforms_item">
                <img className="chatbot_platforms_items_img" src={b} alt="" />
              </div>
            );
          })}
        </div>
      </div>

      {/* footer */}
      <div className="footer">
        <div className="footer_item">
          <img
            src={pora_company_logo}
            alt=""
            className="footer_item_company_img"
          />
        </div>
        <div className="footer_item">
          <div className="footer_item_heading">Company Name</div>
          <div className="footer_item_info">Pora Innovations Pvt Ltd </div>
          <div className="footer_item_info_1">(Startup India recognised)</div>
        </div>
        <div className="footer_item">
          <div className="footer_item_heading">Address</div>
          <div className="footer_item_info">Belapur, New Mumbai.</div>
        </div>
        <div className="footer_item">
          <div className="footer_item_heading">Contact Us</div>
          <div className="footer_item_info">
            <div className="footer_contactUs_parent">
              <div className="footer_contactUs_item">+91 9137359450</div>
              <div className="footer_contactUs_item">
                support@porainnovations.com
              </div>
              <div className="footer_contactUs_item"></div>
            </div>
          </div>
        </div>
      </div>

      {/* privacy and copyright */}
      <div className="privacy_copyright">
        <div className="" onClick={() => navigate("/privacy-policy")}>Privacy Policy</div>
        <div className="" onClick={() => navigate("/terms-of-service")}>Terms of Service</div>
        <div className="">Copyright © PORA INNOVATIONS PVT LTD. All Rights Reserved</div>
      </div>
    </div>
  );
}

function Main() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <div className={window.location.pathname !== "/" ? "navbar_scrollable" : "navbar"}>
        <div className="navbar_left">
          <div className="navbar_logo_parent" onClick={() => navigate("/")}>
            <img className="navbar_logo_img" src={truechat_logo_new} />
            <div className="">
              <div className="navbar_logo_name">TrueChat.live</div>
              <a style={{ fontSize: "12px", cursor: "pointer", color:"black" }} href={"https://porainnovations.com/"}>By PORA Innovations Pvt Ltd</a>
            </div>
          </div>
        </div>
        <div className="navbar_right">
          <div className="navbar_navigation_items">
            <a
              className="navbar_navigation_item"
              target="_blank"
              href="https://porainnovations.com"
            >
              About
            </a>
            <a
              className="navbar_login_button"
              target="_blank"
              href="https://www.app.truechat.live/login"
            >
              Login
            </a>
            {/* <button className="navbar_login_button">Login</button> */}
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfCondition />} />
      </Routes>
    </div>
  )

}

export default Main;
